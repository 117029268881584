<template>
  <div
    v-if="hasPageAccess('resellerHome')"
    id="reseller-dashboard"
  >
    <div class="aira-container">
      <h2
        class="profile-title"
        aria-label="Internal Dashboard"
      >
        Dashboard
      </h2>

      <div class="d-sm-flex justify-content-sm-between flex-wrap">
        <!-- ACCOUNT INFORMATION -->
        <b-card
          v-if="hasPageAccess('resellerProfile')"
          img-top
          class="w-50-100"
        >
          <div>
            <h3 class="profile-heading">
              {{ firstName }} {{ lastName }}
            </h3>
            <!-- EMAIL -->
            <h4 class="profile-subtitle">
              Email
            </h4>
            <p class="profile-body">
              {{ email }}
            </p>
            <!-- <b-btn
              variant="link"
              type="primary"
              @click="goToProfileEditEmail()">
              Update Email
            </b-btn> -->
            <br>
            <!-- PAYMENT PROFILE -->
            <h4 class="profile-subtitle">
              Payment Profile
            </h4>
            <div v-if="hasValidPaymentProfile">
              <div
                v-if="cardType"
                class="profile-body"
              >
                <p>{{ ccFirstName }} {{ ccLastName }}</p>
                <p>{{ cardType }} ending in {{ ccLastFour }}</p>
                <p>{{ address1 }}</p>
                <p>{{ city }}, {{ state }} {{ zip }} {{ country }}</p>
              </div>
              <div
                v-else-if="achAccountType"
                class="profile-body"
              >
                <p>{{ achFullName }}</p>
                <p class="text-uppercase">
                  {{ achAccountType }}
                </p>
                <p>{{ achRoutingNumber }}</p>
                <p>XXXX-{{ achAccountNumberLastFour }}</p>
                <p>{{ address1 }}</p>
                <p>{{ city }}, {{ state }} {{ zip }} {{ country }}</p>
              </div>
              <b-btn
                variant="link"
                class="profile-edit-link"
                @click="goToProfileEditPayment()"
              >
                Edit Payment Profile
              </b-btn>
            </div>
            <div v-else>
              <p class="profile-body">
                Your payment profile is incomplete.
              </p>
              <b-btn
                variant="link"
                class="profile-edit-link"
                @click="goToProfileEditPayment()"
              >
                Update Payment Profile
              </b-btn>
            </div>
          </div>
        </b-card>

        <!-- PURCHASE ORDER -->
        <b-card
          v-if="hasPageAccess('resellerPurchaseOrder')"
          class="w-50-100"
        >
          <h3 class="profile-heading">
            Purchase Order
          </h3>
          <b-btn
            variant="link"
            class="profile-edit-link"
            @click="goToPurchaseOrderForm()"
          >
            Create New Purchase Order <i class="material-icons">arrow_forward</i>
          </b-btn>
        </b-card>
      </div>
    </div>
    <LoginState />
  </div>
  <div v-else>
    <LoginState />
    <div id="dashboardContainer">
      <Error403 />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as role from '../../utils/permissions';

import Error403 from '../Error/403.vue';
import LoginState from '../Login/LoginState.vue';

export default {
  name: 'ResellerHome',
  components: {
    Error403,
    LoginState,
  },
  data() {
    return {
      initializing: true,
    };
  },
  computed: {
    ...mapGetters([
      'errors',
      'needRefresh',
      'email',
      'firstName',
      'lastName',
      'cardType',
      'ccFirstName',
      'ccLastName',
      'ccLastFour',
      'achAccountType',
      'achFullName',
      'achRoutingNumber',
      'achAccountNumberCensored',
      'achAccountNumberLastFour',
      'address1',
      'city',
      'state',
      'zip',
      'country',
    ]),
    hasValidPaymentProfile() {
      return (this.cardType || this.achAccountType);
    },
  },
  async mounted() {
    if (this.errors.length) this.$store.commit('CLEAR_ERRORS');
    if (this.needRefresh) this.$router.go();
    if (this.$session.exists()) {
      await this.$store.dispatch('setSsoToken', this.$session.get('sso-token'));
      const login = await this.$store.dispatch('validateUserToken');
      await this.$store.dispatch('getUserProfile');
      await this.$store.dispatch('getUserBilling');
      if (!login) {
        // login failed, destroy session and cached data (session, sso-token)
        this.$session.destroy();
        this.initializing = false;
      } else {
        this.initializing = false;
      }
    }
    this.initializing = false;
  },
  methods: {
    hasPageAccess: role.hasPageAccess,
    hasComponentAccess: role.hasComponentAccess,
    goToPurchaseOrderForm() {
      this.$router.push('/reseller/purchase-order');
    },
    goToProfileEditEmail() {
      this.$router.push('/reseller/profile/edit/email');
    },
    goToProfileEditPayment() {
      this.$router.push('/reseller/profile/edit/billing');
    },
  },
};
</script>

<style lang="scss">
#reseller-dashboard {
  .reseller-button {
    margin: 10px;
    width: 300px;
    height: 75px;
  }
  .profile-body, .profile-subtitle, .profile-heading, .profile-edit-link {
    display: block;
  }
  .profile-heading {
    font-size: large
  }
  .profile-edit-link {
    float: left;
    opacity: 1;
    i {
      font-size: 12px;
    }
  }
  .card {
    text-align: left;

    height: fit-content;
  }
}
</style>
