<template>
  <div
    v-if="isLoggedIn"
    id="loginState"
  >
    <b-btn
      variant="link"
      size="sm"
      @click="signOut"
    >
      {{ roleLinkText }}
    </b-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LoginState',
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'role',
    ]),
    roleLinkText() {
      return `Log out of roles: ${this.role.join()}`;
    },
  },
  methods: {
    async signOut() {
      this.$session.destroy();
      await this.$store.dispatch('logout');
    },
  },
};
</script>

<style lang="scss">
#loginState {
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  text-align: center;
}
</style>
